<!-- added fpl class to add style specific to this section -->
<div [formGroup]="form" class="fpl">
    <div class="col-12">
        <label class="mr-2 lbl"> Select year: </label>
        <span *ngFor="let fplItem of fplDataRecords">
            <p-radioButton (onClick)="selectYear(fplItem)" class="mr-2" formControlName="year" [(ngModel)]="year" [value]="fplItem.year" [inputId]="fplItem.year">
            </p-radioButton>
            <label for="year" class="mr-3"> {{ fplItem.year }} </label>
        </span>
    </div>
    <div class="col-6 ml-1">
        <label class="lbl">Family size in persons: </label>
        <input class="w-full mt" (keyup)="calculatePercentOfFPL()" formControlName="familySize" pInputText
            type="number">
    </div>
    <div class="grid ml-1">
        <div class="col-6">
            <label class="lbl">Annually:</label>
            <div>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-dollar"></i>
                    <input class="w-full" (keyup)="calculatePercentOfFPL()" formControlName="annually"
                        [value]="annually" pInputText type="number">
                </span>
            </div>

        </div>
        <div class="col-6">
            <label class="lbl">Monthly: </label>
            <div>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-dollar"></i>
                    <input class="w-full" (keyup)="changeMonthlyValue()" formControlName="monthly" [value]="monthly"
                        pInputText type="number">
                </span>
            </div>
        </div>
        <div class="col-6">
            <label class="lbl">Biweekly: </label>
            <div>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-dollar"></i>
                    <input class="w-full" (keyup)="changeBiweeklyValue()" formControlName="biweekly" [value]="biweekly"
                        pInputText type="number">
                </span>
            </div>
        </div>
        <div class="col-6">
            <label class="lbl">Weekly: </label>
            <div>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-dollar"></i>
                    <input class="w-full" (keyup)="changeWeeklyValue()" formControlName="weekly" [value]="weekly"
                        pInputText type="number">
                </span>
            </div>
        </div>
    </div>
    <div class="col-6">
        <label class="lbl">Percent of FPL: </label>
        <!-- <input (keyup)="changePercentOfFPL()" formControlName="percentOfFPL" [value]="calculatedPercentOfFpl" pInputText
            type="number"> -->
        <span class="validation-star"> <b> {{ calculatedPercentOfFpl }} </b> <b *ngIf="calculatedPercentOfFpl"> % </b>
        </span>
    </div>
    <div class="col-12">
        <label class="lbl">Enter % of monthly FPL: </label>

        <!-- <input (keyup)="calculateMonthlyFPL()" formControlName="percentage" pInputText type="number"> -->
        <span class="p-input-icon-left ">
            <i class="pi pi-percentage"></i>
            <input (keyup)="calculateMonthlyFPL()" formControlName="percentage" pInputText type="number">
        </span>
    </div>
    <div class="col-12">
        <label class="lbl">Monthly FPL: </label>
        <b> {{ monthlyFPL }} </b>
    </div>
</div>