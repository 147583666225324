import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConstants } from '@app/_config';
import { SysService, UserService } from '@app/_common';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

//@Injectable({
//  providedIn: 'root'
//})
export class MenuService {
  /* Retrun user menu statically or from backend */
  static messageService: MessageService;
  // static dialogService: DialogService;
  static SysService: SysService;
  public static getMenuUser(isAdmin: boolean, userService: UserService, router: Router, messageService: MessageService) {
    this.messageService = messageService;
    // public static getMenuUser(isAdmin: boolean, userService: UserService, router: Router, messageService: MessageService) {
    //   this.messageService = messageService;
    // this.dialogService = dialogService;
    if (userService.isLoggedIn()) {
      //Based on role you can return user menu or admin menu  
      const user = userService.getUser();
      if (isAdmin) {
        return this.getUserMenu(router, user, userService);
      } else {
        return this.getAgentMenu(router);
      }
    }
    return []; //no menu
  }

  private static getUserMenu(router: Router, user, userService: UserService) {
    let menu: any = [];
    if (userService.hasPermission(['viewboroughs', 'manageboroughs'], user)) {
      menu.push({ label: 'Borough', command: (event) => { this.link('/borough', router) } })
    }
    if (userService.hasPermission(['viewoffices', 'manageoffices'], user)) {
      menu.push({ label: 'Office Locations', command: (event) => { this.link('/office-location', router) } })
    }
    if (userService.hasPermission(['viewpracticeareas', 'managepracticeareas'], user)) {
      menu.push({ label: 'Practice area', command: (event) => { this.link('/practice-area', router) } })
    }
    if (userService.hasPermission(['manageissues', 'managesubissues'], user)) {
      menu.push({ label: 'Issue', command: (event) => { this.link('/setting-issue', router) } })
    }
    if (userService.hasPermission(['viewattorney', 'manageattorneys'], user)) {
      menu.push({ label: 'Advocates', command: (event) => { this.link('/attorney', router) } })
    }
    if (userService.hasPermission(['viewnointakereasons', 'managenointakereasons'], user)) {
      menu.push({ label: 'No intake reasons', command: (event) => { this.link('/no-intake-reasons', router) } })
    }
    if (userService.hasPermission(['viewusers', 'manageusers'], user)) {
      menu.push({ label: 'Users', command: (event) => { this.link('/users', router) } })
    }
    if (userService.hasPermission(['viewtasktypes', 'managetasktypes'], user)) {
      menu.push({ label: 'Task type', command: (event) => { this.link('/task-type', router) } })
    }
    if (userService.hasPermission(['viewsettings', 'managesettings'], user)) {
      menu.push({ label: 'Email', command: (event) => { this.link('/email', router) } })
    }



    // items: [
    //   { label: 'Borough', command: (event) => { this.link('/borough', router) } },
    //   { label: 'Practice Area', command: (event) => { this.link('/practice-area', router) } },
    //   { label: 'Issue', command: (event) => { this.link('/setting-issue', router) } },
    //   { label: 'Office Locations', command: (event) => { this.link('/office-location', router) } },
    //   { label: 'Advocates', command: (event) => { this.link('/attorney', router) } },
    //   { label: 'No Intake Reasons', command: (event) => { this.link('/no-intake-reasons', router) } },
    //   { label: 'Roles and Privilages', command: (event) => { this.link('/', router) } },
    //   { label: 'Customers', command: (event) => { this.link('/customers', router) } },
    //   { label: 'Users', command: (event) => { this.link('/users', router) } },
    //   { label: 'Task Type', command: (event) => { this.link('/task-type', router) } },
    // ]
    return menu;
  }



  private static getAgentMenu(router: Router) {
    return [
      { label: 'Home', icon: 'pi pi-home', command: (event) => { this.link('/', router) } },
      // {
      //   label: 'Snapshot',
      //   items: [
      //     { label: 'My Piedmont Snapshot', command: (event) => { this.link('/snapshot', router) } }
      //     ]
      //   },
    ]
  }
  static link(path, router) {
    SysService.scrollTo(0);
    this.messageService.clear();
    router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigate([path]));
  }

  logout(): void {
    MenuService.SysService.signOut();

  }
}


