<header *ngIf="!sysService.isLoggedIn()"
	class="header">
	<div class="heading">
		<h1 class="headr">
			Access Line Integrated Intake System
		</h1>
	</div>
</header>
<header *ngIf="sysService.isLoggedIn()"
	class="header inner">
	<div class="heading">
		<h1 class="headr">
			Access Line Integrated Intake System
		</h1>
	</div>
	<div *ngIf="!sysService.isAdmin() && sysService.hasPermission(['viewresources','manageresources'])"
		class="mr-6">
		<button (click)="navResources()"
			pButton
			pRipple
			type="button"
			label="Resources"
			class="p-button p-button-text w-10rem h-3rem head-btn btn-fmly">
			<img src="./assets/images/Resources.png">
		</button>
	</div>
	<div *ngIf="!sysService.isAdmin()"
		class="mr-6">
		<button (click)="navAgents()"
			pButton
			pRipple
			type="button"
			label="Agent Desk"
			class="p-button p-button-text w-10rem h-3rem head-btn btn-fmly">
			<img src="./assets/images/agentdesk.png">
		</button>
	</div>

	<div *ngIf="getTimerFlag()"
		style="color:red;margin-left:-40px;min-width: 50px;"
		class="mr-6"
		id="myId">
		<b>{{getTimer()}}</b>
	</div>

	<div *ngIf="getCallFlag()"
		style="margin-left:-40px;width:50px;"
		class="mr-6"
		id="myId">
		<img src="./assets/images/newcall.gif">
	</div>

	<!-- <div class="mr-6" *ngIf="!sysService.isAdmin()">
    <button pButton pRipple type="button" label="FPL Calculator" (click)="navFplCalculator()"
      class="p-button-outlined w-10rem h-3rem">
      <i class="pi pi-tablet"></i>
    </button>
  </div> -->
	<div class="mr-1 w-10rem">
		<span class="userName">
			{{this.sysService.getUser().full_name}}
		</span>
	</div>

	<!-- <h3 class="w-10rem">{{this.sysService.getUser().full_name}}</h3> -->
	<div *ngIf="sysService.isAdmin()"
		class="p-1">
		<button (click)="menu.toggle($event)"
			pButton
			pRipple
			type="button"
			class="p-button-rounded p-button-info p-button-text">
			<i class="pi pi-cog"
				style="font-size: 2rem"></i>
		</button>
		<p-menu #menu
			[popup]="true"
			[model]="sysService.menuItems"></p-menu>
	</div>
	<div class="p-ml-auto dropdown"
		tabindex="0">
		<a class="p-link"
			title>
			<button pButton
				pRipple
				type="button"
				icon="pi pi-user"
				class="p-button-rounded p-button-info p-button-outlined"></button>
		</a>
		<ul class="dropdown-menu a-r">
			<li *ngFor="let menu of menus">

				<!-- <a class="nav-link"
					href="{{this.url}}/auth/signout">
					<i class="icon {{menu.icon}}"></i>
					<span class="mx-2">{{menu.label}}</span>
				</a> -->
				<button (click)="menu.command($event)"
					type="button"
					class="nav-link">
					<i class="icon {{menu.icon}}"></i>
					<span class="mx-2 btn-fmly">{{menu.label}}</span>
				</button>

			</li>
		</ul>
	</div>
	<!-- <div class="p-mr-auto">
    <button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-info p-button-outlined"
      (click)="logout();" title="{{sysService.username}}"></button>
  </div> -->
	<div class="mr-3"></div>

</header>
