import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'home-okta',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: '',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'login-okta',
        loadChildren: () => import('./login-okta/login-okta.module').then(m => m.LoginOktaModule)
      },
      {
        path: 'logout-okta',
        loadChildren: () => import('./logout-okta/logout-okta.module').then(m => m.LogoutOktaModule)
      },
      {
        path: 'agent',
        loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule)
      },
      {
        path: 'fpl',
        loadChildren: () => import('./fpl/fpl.module').then(m => m.FplModule)
      },
      {
        path: 'scripts',
        loadChildren: () => import('./script/script.module').then(m => m.scriptModule)
      },
      {
        path: 'issues',
        loadChildren: () => import('./issues/issue.module').then(m => m.IssueModule)
      },
      {
        path: 'office-location',
        loadChildren: () => import('./office-location/office-location.module').then(m => m.OfficeLocationModule)
      },
      {
        path: 'practice-area',
        loadChildren: () => import('./practice-area/practice-area.module').then(m => m.PracticeAreaModule)
      },
      {
        path: 'attorney',
        loadChildren: () => import('./attorney/attorney.module').then(m => m.AttorneyModule)
      },
      {
        path: 'caller',
        loadChildren: () => import('./caller/caller.module').then(m => m.CallerModule)
      },
      {
        path: 'calender',
        loadChildren: () => import('./calendar/calendar-main.module').then(m => m.MyCalendarModule)
      },
      {
        path: 'borough',
        loadChildren: () => import('./borough/borough.module').then(m => m.BoroughModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'no-intake-reasons',
        loadChildren: () => import('./no-intake-reasons/no-intake-reasons.module').then(m => m.NoIntakeReasonsModule)
      },
      {
        path: 'calllogs',
        loadChildren: () => import('./call-logs/call-logs.module').then(m => m.CallLogsModule)
      },
      {
        path: 'tasks',
        loadChildren: () => import('./followup-tasks/followup-tasks.module').then(m => m.FollowuptasksModule)
      },
      {
        path: 'setting-issue',
        loadChildren: () => import('./setting-issue/setting-issue.module').then(m => m.SettingIssueModule)
      },
      {
        path: 'transfer-history',
        loadChildren: () => import('./issue-transfer-history/issue-transfer-history.module').then(m => m.IssueTransferHistoryModule)
      },
      {
        path: 'task-type',
        loadChildren: () => import('./task-type/task-type.module').then(m => m.TaskTypeModule)
      },
      {
        path: 'email',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
      },
      {
        path: 'ringcentral',
        loadChildren: () => import('./ringcentral/ringcentral.module').then(m => m.RingcentralModule)

      },
      {
        path: 'admin_dashboard',
        loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdmindashboardModule)

      },
      // otherwise redirect to home
      { path: '**', redirectTo: 'login' }

    ]
  }
  // { path: 'login-response', loadChildren: () => import('./login-okta/login-okta.module').then(m => m.LoginOktaModule) },

];


export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
