import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorProviders, ErrorInterceptorProviders, SharedModule, ViewFactory } from '@app/_common';
import { SysService } from '@app/_common';
import { DialogService } from 'primeng/dynamicdialog';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MessagesModule,
    MessageModule,
    PanelMenuModule,
    SharedModule,
    DialogModule,
    ConfirmDialogModule,
    AppRoutingModule,
    //    DropdownModule
  ],
  providers: [AuthInterceptorProviders, ErrorInterceptorProviders, ConfirmationService, MessageService, DialogService, PrimeNGConfig, SysService, ViewFactory],
  bootstrap: [AppComponent]
})
export class AppModule { }

