import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { View, ViewFactory } from '@app/_common';

@Component({
  selector: 'app-fpl-calculator',
  templateUrl: './fpl-calculator.component.html',
  styleUrls: ['./fpl-calculator.component.scss']
})
export class FplCalculatorComponent implements OnInit {
  private fplView: View = this.viewFactory.newView();
  form: FormGroup;
  calculatedPercentOfFpl: any;
  POVERTY_THRESHOLD: number = 0;
  THRESHOLD_EXTRA: number = 0;
  monthly: any;
  biweekly: any;
  weekly: any;
  annually: any;
  annualFPL: any;
  monthlyFPL: any;
  year: string = "2022";
  fplDataRecords: any[];

  constructor(private _formBuilder: FormBuilder, private viewFactory: ViewFactory) { }

  ngOnInit(): void {
    this.getFPLthresholdData();
    this.form = this._formBuilder.group({
      year: [],
      familySize: [1],
      annually: [],
      monthly: [],
      biweekly: [],
      weekly: [],
      percentOfFPL: [],
      percentage: []
    });
  }

  calculatePercentOfFPL() {
    this.calculatedPercentOfFpl = ((this.form.value.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
    this.monthly = (this.form.value.annually / 12).toFixed(2);
    this.biweekly = (this.form.value.annually / 24).toFixed(2);
    this.weekly = (this.form.value.annually / 52).toFixed(2);
  }

  changeMonthlyValue() {
    this.annually = (this.form.value.monthly * 12).toFixed(2);
    this.biweekly = (this.form.value.monthly / 2).toFixed(2);
    this.weekly = (this.form.value.monthly / 4).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  changeBiweeklyValue() {
    this.weekly = (this.form.value.biweekly / 2).toFixed(2);
    this.monthly = (this.form.value.biweekly * 2).toFixed(2);
    this.annually = (this.monthly * 12).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  changeWeeklyValue() {
    this.biweekly = (this.form.value.weekly * 2).toFixed(2);
    this.monthly = (this.form.value.weekly * 4).toFixed(2);
    this.annually = (this.monthly * 12).toFixed(2);
    this.calculatedPercentOfFpl = ((this.annually / (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) * 100).toFixed(2);
  }

  //  This method should be called for reverse calculation. If we enter % of FPL and need to calculate annually, monthly, biweekly and weekly income.
  // changePercentOfFPL() {
  //   this.annually = ((this.form.value.percentOfFPL * (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA))) / 100).toFixed(2);
  //   this.monthly = (this.annually / 12).toFixed(2);
  //   this.biweekly = (this.annually / 24).toFixed(2);
  //   this.weekly = (this.annually / 52).toFixed(2);
  // }

  calculateMonthlyFPL() {
    this.annualFPL = (this.POVERTY_THRESHOLD + ((this.form.value.familySize - 1) * this.THRESHOLD_EXTRA)).toFixed(2);
    this.monthlyFPL = (((this.annualFPL / 12) * this.form.value.percentage) / 100).toFixed(2);
  }

  selectYear(fplItem) {
    console.log(fplItem);
    const fieldsToReset = {
      familySize: 1,
      annually: "",
      monthly: "",
      biweekly: "",
      weekly: "",
      percentOfFPL: "",
      percentage: ""
    }
    this.form.patchValue(fieldsToReset)
    this.calculatedPercentOfFpl = 0;
    this.monthlyFPL = 0;
    this.POVERTY_THRESHOLD = fplItem.poverty_threshold;
    this.THRESHOLD_EXTRA = fplItem.threshold;
  }

  getFPLthresholdData() {
    this.fplView.getData("/fpl-calculator/last-record").then((data: any[]) => {
      console.log(data);
      this.fplDataRecords = data;
      const defaultSelectedValues = this.fplDataRecords.filter(item => item.year == this.year);
      this.POVERTY_THRESHOLD = defaultSelectedValues[0].poverty_threshold;
      this.THRESHOLD_EXTRA = defaultSelectedValues[0].threshold;
    })
  }

}
